<template>
  <div class="admin-subscription-list">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="green-divider"></div>
    <!--    Bootstrap Table-->
    <b-table striped hover :items="subList" :fields="subFields" select-mode="single" selected-variant="success"
              :current-page="currentPage" :per-page="perPage"
             th-class="bv-table-header"
             class="bv-table">
      <!--User Actions Row Element-->
      <template v-slot:cell(actions)="row">
        <i class="fa fa-times-circle row-icon-button-danger row-action ml-3" v-b-tooltip.hover title="Delete"
           @click="clickActionDelete(row.item)"></i>
      </template>
    </b-table>
    <div class="green-divider"></div>
    <div class="page-bar">
      <b-form-select
        variant="dark"
        v-model="perPage"
        id="perPageSelect"
        size="sm"
        :options="pageOptions"
        class="page-select">
      </b-form-select>
      <b-pagination
        variant="dark"
        v-model="currentPage"
        :total-rows="subList.length"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="page-buttons"></b-pagination>
    </div>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'
import * as DataProvider from '../helpers/DataProvider'
import * as AlertHelper from '../helpers/AlertHelper'
import * as ErrorHelper from '../helpers/ErrorHelper'
export default {
  name: 'admin-subscription-list',
  components: {
    LoadingBox
  },
  props: {
    subscriptions: Array
  },
  data: function () {
    return {
      loading: false,
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      subFields: [
        { key: 'id',
          label: '#',
          sortable: true
        },
        { key: 'subscription_id',
          label: 'Subscription Id',
          sortable: true
        },
        { key: 'product_name',
          label: 'Name',
          sortable: true
        },
        { key: 'product_id',
          label: 'Product Id',
          sortable: true
        },
        { key: 'status',
          label: 'Status',
          sortable: true
        },
        { key: 'latest_volume',
          label: 'Volume',
          sortable: true
        },
        { key: 'last_update',
          label: 'Updated',
          sortable: true
        },
        { key: 'actions',
          label: 'Actions'
        }
      ],
      subList: []
    }
  },
  async created () {
    if (this.subscriptions) {
      // Disable Advanced Filter Controls
      this.subList = this.subscriptions
      // We've been given a list of specific users to display. Do a thing to get the right users.
      // TODO - How do we identify users? Email? Users ID? What does a device provide to track ownership if queried?
    } else {
      console.error('Subscription List was not given any data!')
    }
  },
  methods: {
    clickActionDelete: async function (item) {
      let confirm = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete Subscription with id ${item.id}?`,
        'Confirm Delete')
      if (confirm) {
        let resp = await DataProvider.adminDeleteSubscription(item.id)
        if (resp.success) {
          AlertHelper.successToast('Subscription Deleted successfully.', 'Subscription Deleted.')
          this.$emit('change')
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
    }
  },
  watch: {
    subscriptions: function (newVal, oldVal) {
      this.subList = this.subscriptions
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .user-list{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .row-action {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }
</style>
